.header__avatar{
    display: flex;
    width:200px;
    align-items: center;
    justify-content: flex-end;
}
.header__username{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #C9CED6;
    margin: 0px 16px;
}

.ant-switch-checked {
    background-color: #2E3A59 !important;
}